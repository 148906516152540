import { ENV_CONFIGS } from '@/js/configs.js'
import CITIES from './cities.json'

export const telecomCow = [
  '10O',
  '11O',
  '12O',
  '13O',
  '14O',
  '15O',
  '16O',
  '17O',
  '18O',
  '19O',
  '1FE',
  '1S1',
  '1S2',
  '1S3',
  '1S4',
  '1S5',
  '1S6',
  '1S7',
  '1S8',
  '1S9',
  '1SA',
  '1SB',
  '1SC',
  '1SD',
  '1SE',
  '1SF',
  '1SG',
  '1SH',
  '1SL',
  '1SM',
  '1SP',
  '1SQ',
  '1SX',
  '1SY',
  '1SZ',
  'ADB',
  'ADD',
  'ADP',
  'ADS',
  'AT5',
  'AT9',
  'AVD',
  'AZO',
  'BDT',
  'BSS',
  'BTD',
  'BTL',
  'BTR',
  'C0W',
  'C1W',
  'C2W',
  'C7W',
  'CBI',
  'CIX',
  'CON',
  'COR',
  'COS',
  'COV',
  'COW',
  'COX',
  'COZ',
  'CQW',
  'CW3',
  'DCE',
  'DCO',
  'DL3',
  'DLU',
  'DVV',
  'E5S',
  'ES5',
  'EZI',
  'FQN',
  'FV1',
  'FV5',
  'FV6',
  'FV7',
  'FV8',
  'FV9',
  'FVA',
  'FVB',
  'FVC',
  'FVH',
  'FVI',
  'FVJ',
  'FVK',
  'FVM',
  'FVN',
  'FVO',
  'FVQ',
  'FVR',
  'FVS',
  'FVU',
  'FVV',
  'FVX',
  'FVY',
  'FVZ',
  'GCP',
  'GDY',
  'GEP',
  'GIE',
  'GIY',
  'GPC',
  'GPI',
  'GPQ',
  'GPY',
  'GYI',
  'IJN',
  'IOO',
  'JOW',
  'JZO',
  'KEA',
  'KJY',
  'KOS',
  'KYY',
  'MFD',
  'MM1',
  'MM5',
  'MMS',
  'N1X',
  'N7V',
  'NMN',
  'NOX',
  'NV5',
  'PKC',
  'PPE',
  'PPF',
  'PPG',
  'PPH',
  'PPI',
  'PPJ',
  'PQH',
  'QYJ',
  'RS5',
  'RSS',
  'S0S',
  'S1S',
  'S4S',
  'SJX',
  'SMS',
  'SNS',
  'SRJ',
  'SXJ',
  'SXY',
  'TC0',
  'TC1',
  'TC2',
  'TC3',
  'TC4',
  'TC5',
  'TC6',
  'TC7',
  'TC8',
  'TC9',
  'TCA',
  'TCD',
  'TCE',
  'TCF',
  'TCI',
  'TCJ',
  'TCN',
  'TCP',
  'TCQ',
  'TCS',
  'TCY',
  'TCZ',
  'TI2',
  'TI3',
  'TK0',
  'TL1',
  'TL4',
  'TL5',
  'TL6',
  'TL7',
  'TL8',
  'TL9',
  'TLB',
  'TLC',
  'TLD',
  'TLE',
  'TLI',
  'TLJ',
  'TLM',
  'TLN',
  'TLO',
  'TLQ',
  'TLS',
  'TLU',
  'TLY',
  'TLZ',
  'TOI',
  'VDM',
  'W1N',
  'W4N',
  'WDW',
  'WIV',
  'WOS',
  'WRM',
  'YQZ',
  'YXW',
  'ZWI'
]

export const computeBillTitle = (bill) => {
  const billDate = new Date(
    bill.dataEmissione.split('/')[2],
    bill.dataEmissione.split('/')[1],
    bill.dataEmissione.split('/')[0]
  )
  const newBillingSystemDate = new Date('2019', '7', '1')

  if (billDate < newBillingSystemDate) {
    return 'Bolletta ' + bill.idFattura
  }

  const billingType = bill.tipoFattura
  const billingGroup = bill.gruppoFatturazione

  if (billingType === 'Fattura' || billingType === 'Fine nota') {
    if (bill.commodity === 'fiber') {
      return 'Bolletta'
    } else {
      if (bill.ricalcolo) {
        return 'Bolletta con ricalcoli'
      } else {
        return 'Bolletta'
      }
    }
  }

  if (billingType === 'fattura manuale') {
    switch (billingGroup) {
      case 'Canone RAI – Rimbors':
        return 'Rimborso canone RAI'
      case 'Canone Rai FC':
        return 'Canone RAI'
      case 'Fatt. beni/servizi':
        return 'Beni / Servizi'
      case 'Fatturazione CMOR':
        return 'Corrispettivo morosità'
      case 'Fatturazione Ind':
        return 'Indennizzo'
      case 'Fatturazione Ind 100/2016 Fatt.Chiusura':
        return 'Indennizzo'
      case 'Fatturazione Ind 463/2016 TIF':
        return 'Indennizzo'
      case 'Fatturazione MDC':
        return 'Lavori tecnici'
      case 'Indennizzi Del.463 – Fatture di periodo':
        return 'Indennizzo'
      case 'Manual Billing':
        return 'Altre partite'
      case '':
        return 'Altre partite'
      default:
        return 'Fattura manuale'
    }
  }

  if (
    billingType === 'Nota di correzione' ||
    billingType === 'Fattura ultima'
  ) {
    return 'Ricalcoli'
  }

  if (billingType === 'Nota di accredito') {
    return 'Riaccredito bolletta'
  }

  return 'Bolletta'
}

export const computeBillStatusLabel = (state) => {
  switch (state) {
    case '1':
      return 'In scadenza' // this should be 'In scadenza', but requiremnt is to transcode it to 'In pagamento' as for id '2'
    case '2':
      return 'In pagamento'
    case '3':
      return 'Scaduta'
    case '4':
      return 'Pagata'
    case '5':
      return 'Sospesa per richiesta in corso'
    case '6':
      return 'In verifica'
    case '7':
      return 'Rateizzazione in corso'
    case 'NA':
      return 'N/A'
  }
}

export const computeBillPaymentDelay = (bill) => {
  // console.log('importo:' + bill.importo + ' computedStatus:' + bill.computedStatus + ' idStato:' + bill.idStato + ' mdp:' + bill.mdp)

  let currentDate = new Date()
  let formattedExpiration = bill.dataScadenza.split('/').reverse().join('/')
  let expirationDate = new Date(formattedExpiration)

  if (bill.computedStatus === '2' && ['BLP', 'BON'].includes(bill.mdp))
    return null
  else if (bill.computedStatus === '6' && ['BLP', 'BON'].includes(bill.mdp))
    return 6
  else if (
    bill.computedStatus === '2' &&
    bill.mdp === 'SDD' &&
    currentDate >= expirationDate
  )
    return 7
  else if (
    bill.computedStatus === '2' &&
    ['CCR', 'DPAY'].includes(bill.mdp) &&
    currentDate >= expirationDate
  )
    return 4
  else if (
    bill.computedStatus === '6' &&
    bill.idStato === '3' &&
    ['SDD', 'CCR', 'DPAY'].includes(bill.mdp) &&
    currentDate >= expirationDate
  )
    return 6

  // console.log('no disclamer for payment ' + bill.importo)

  return null
}

export const categoryIds = {
  lightGas: ENV_CONFIGS.CATEGORY_LIGHTGAS,
  fiber: ENV_CONFIGS.CATEGORY_FIBER,
  pdr: ENV_CONFIGS.CATEGORY_PDR,
  energy: ENV_CONFIGS.ENERGY,
  photovoltaic: ENV_CONFIGS.PHOTOVOLTAIC
}

export const categoryProperties = {
  [categoryIds.lightGas]: {
    access: [
      'hasGasActive',
      'hasGasActivating',
      'hasLightActive',
      'hasLightActivating'
    ],
    filename: 'supporto_luce-gas.svg'
  },
  [categoryIds.fiber]: {
    access: ['isFiberEligible', 'hasFiberActive', 'hasFiberActivating'],
    filename: 'ic-wifi-blu.svg'
  },
  [categoryIds.pdr]: {
    access: [
      'hasGasActive',
      'hasGasActivating',
      'hasLightActive',
      'hasLightActivating'
    ],
    filename: 'pod-pdr.svg'
  },
  [categoryIds.energy]: { access: [] },
  [categoryIds.photovoltaic]: {
    access: ['hasPhotovoltaicActive', 'hasPhotovoltaicActivating']
  }
}

export const supportIds = {
  request: '3',
  contacts: '4'
}

export const supportProperties = {
  [supportIds.request]: { filename: 'info-big.svg', route: 'NewRequest' },
  [supportIds.contacts]: { filename: 'contattaci.svg', route: 'Contacts' }
}

export const requestCategoriesPhotovoltaic = {
  categories: [
    'Malfunzionamento batterie',
    'Malfunzionamento inverter',
    'Malfunzionamento impianto',
    'Malfunzionamento wallbox',
    'Malfunzionamento pompa di calore',
    'Malfunzionamento ottimizzatori',
    'Informazioni contratto',
    'Dati di monitoraggio impianto',
    'Credenziali di accesso APP',
    'Informazioni su nuove offerte e iniziative Sorgenia',
    'Altro'
  ],
  typology: {
    'Malfunzionamento batterie': [
      'Batterie non funzionanti',
      'Batterie completamente spente',
      'Batterie che non si caricano',
      'Batterie che non si scaricano',
      'Batterie in allarme'
    ],
    'Malfunzionamento inverter': [
      'Inverter spento',
      'Inverter produce poco',
      'Inverter che non produce',
      'Inverter in allarme',
      'Inverter non immette in rete',
      'Rumore proveniente dall’inverter'
    ],
    'Malfunzionamento impianto': [
      'Impianto disconnesso dalla rete',
      'Impianto completamente spento'
    ],
    'Malfunzionamento wallbox': [
      'Wallbox spenta',
      'Wallbox in allarme',
      'Wallbox che non carica',
      'Rumore proveniente dalla wallbox'
    ],
    'Malfunzionamento pompa di calore': [
      'PDC non scalda',
      'PDC non eroga acqua calda',
      'PDC in allarme'
    ],
    'Malfunzionamento ottimizzatori': [
      'Ottimizzatore guasto',
      'Ottimizzatori disconnessi',
      'Ottimizzatori in allarme'
    ],
    'Informazioni contratto': [
      'Installazione impianto',
      'Allaccio alla rete',
      'Offerta commerciale sottoscritta',
      'Informazioni kit contrattuale o documenti tecnici',
      'Informazioni componenti impianto',
      'Dati anagrafici e di contatto',
      'Pagamento fatture (se in tranches/finanziamento)',
      'Attivazione Ritiro Dedicato/Scambio sul posto'
    ],
    'Dati di monitoraggio impianto': [
      'Dati di Produzione e Consumo non caricati correttamente',
      'Dati di Produzione e Consumo non aggiornati',
      'Accesso e visualizzazione grafici di dettaglio',
      'Informazioni sul calcolo risparmio'
    ],
    'Credenziali di accesso APP': [
      'Recupero credenziali',
      'Password dimenticata'
    ],
    'Informazioni su nuove offerte e iniziative Sorgenia': [
      'Luce',
      'Gas',
      'Fiber',
      'Fotovoltaico',
      'Mobilità elettrica',
      'Greeners'
    ],
    Altro: ['']
  },
  mapping: {
    'Malfunzionamento batterie': 'BATTERY_MALFUNCTION',
    'Malfunzionamento inverter': 'INVERTER_MALFUNCTION',
    'Malfunzionamento impianto': 'SYSTEM_MALFUNCTION',
    'Malfunzionamento wallbox': 'WALLBOX_MALFUNCTION',
    'Malfunzionamento pompa di calore': 'HEAT_PUMP_MALFUNCTION',
    'Malfunzionamento ottimizzatori': 'OPTIMIZER_MALFUNCTION',
    'Informazioni contratto': 'CONTRACT_INFORMATION',
    'Dati di monitoraggio impianto': 'SYSTEM_MONITORING_DATA',
    'Credenziali di accesso APP': 'APP_ACCESS_CREDENTIALS',
    'Informazioni su nuove offerte e iniziative Sorgenia':
      'SOREGNIA_NEW_OFFERS_AND_INITIATIVES',
    Altro: 'OTHER',
    'Batterie non funzionanti': 'BATTERIES_NON_FUNCTIONING',
    'Batterie completamente spente': 'BATTERIES_COMPLETELY_DEAD',
    'Batterie che non si caricano': 'BATTERIES_NOT_CHARGING',
    'Batterie che non si scaricano': 'BATTERIES_NOT_DISCHARGING',
    'Batterie in allarme': 'BATTERIES_IN_ALARM',

    'Inverter spento': 'INVERTER_OFF',
    'Inverter produce poco': 'INVERTER_PRODUCING_LOW',
    'Inverter che non produce': 'INVERTER_NOT_PRODUCING',
    'Inverter in allarme': 'INVERTER_IN_ALARM',
    'Inverter non immette in rete': 'INVERTER_NOT_FEEDING_INTO_GRID',
    'Rumore proveniente dall’inverter': 'NOISE_FROM_INVERTER',

    'Impianto disconnesso dalla rete': 'SYSTEM_DISCONNECTED_FROM_GRID',
    'Impianto completamente spento': 'SYSTEM_COMPLETELY_OFF',

    'Wallbox spenta': 'WALLBOX_OFF',
    'Wallbox in allarme': 'WALLBOX_IN_ALARM',
    'Wallbox che non carica': 'WALLBOX_NOT_CHARGING',
    'Rumore proveniente dalla wallbox': 'NOISE_FROM_WALLBOX',

    'PDC non scalda': 'HEAT_PUMP_NOT_HEATING',
    'PDC non eroga acqua calda': 'HEAT_PUMP_NOT_PROVIDING_HOT_WATER',
    'PDC in allarme': 'HEAT_PUMP_IN_ALARM',

    'Ottimizzatore guasto': 'OPTIMIZER_FAULTY',
    'Ottimizzatori disconnessi': 'OPTIMIZERS_DISCONNECTED',
    'Ottimizzatori in allarme': 'OPTIMIZERS_IN_ALARM',

    'Installazione impianto': 'SYSTEM_INSTALLATION',
    'Allaccio alla rete': 'GRID_CONNECTION',
    'Offerta commerciale sottoscritta': 'SIGNED_COMMERCIAL_OFFER',
    'Informazioni kit contrattuale o documenti tecnici':
      'CONTRACT_KIT_OR_TECHNICAL_DOCUMENTS_INFORMATION',
    'Informazioni componenti impianto': 'SYSTEM_COMPONENTS_INFORMATION',
    'Dati anagrafici e di contatto': 'PERSONAL_AND_CONTACT_DATA',
    'Pagamento fatture (se in tranches/finanziamento)':
      'BILL_PAYMENTS_IF_IN_TRANCHES_OR_FINANCING',
    'Attivazione Ritiro Dedicato/Scambio sul posto':
      'DEDICATED_WITHDRAWAL_OR_ON_SITE_EXCHANGE_ACTIVATION',

    'Dati di Produzione e Consumo non caricati correttamente':
      'PRODUCTION_AND_CONSUMPTION_DATA_NOT_LOADED_CORRECTLY',
    'Dati di Produzione e Consumo non aggiornati':
      'OUTDATED_PRODUCTION_AND_CONSUMPTION_DATA',
    'Accesso e visualizzazione grafici di dettaglio':
      'ACCESS_AND_VIEWING_OF_DETAILED_GRAPHS',
    'Informazioni sul calcolo risparmio': 'SAVINGS_CALCULATION_INFORMATION',

    'Recupero credenziali': 'CREDENTIALS_RECOVERY',
    'Password dimenticata': 'FORGOTTEN_PASSWORD',

    Luce: 'ELECTRICITY',
    Gas: 'GAS',
    Fibra: 'FIBER',
    Fotovoltaico: 'SOLAR_PHOTOVOLTAIC',
    'Comunità energetiche': 'GREENERS ',
    'Mobilità elettrica': 'ELECTRIC_MOBILITY',

    'Campo Libero': 'OTHER'
  }
}

export const requestCategories = {
  categories: [
    'Informazioni Contratto',
    'Metodo di pagamento',
    'Informazioni fatture',
    'Supporto clienti'
  ],
  typology: {
    'Informazioni Contratto': [
      'Attivazione',
      'Offerta commerciale sottoscritta',
      'Dati anagrafici',
      'Voltura o Subentro',
      'Chiusura o Disdetta'
    ],
    'Metodo di pagamento': [
      'Metodo di pagamento attivo',
      'Attivazione addebito diretto in CC',
      'Modifica IBAN di addebito in CC',
      'Rateizzazione'
    ],
    'Informazioni fatture': [
      'Spedizione o ricezione fattura',
      'Data emissione fattura',
      'Letture contatore',
      'Consumi e importi fatturati',
      'Conguaglio'
    ],
    'Supporto clienti': [
      'Stato pratiche aperte',
      'Informazioni sulla modulistica',
      'Informazioni su nuove offerte',
      'Problemi e suggerimenti Area Clienti o APP'
    ]
  },
  mapping: {
    Attivazione: 'Informazioni contratto-Attivazione',
    'Offerta commerciale sottoscritta':
      'Informazioni contratto-Offerta commerciale sottoscritta',
    'Dati anagrafici': 'Informazioni contratto-Dati anagrafici',
    'Voltura o Subentro': 'Informazioni contratto-Voltura o Subentro',
    'Chiusura o Disdetta': 'Informazioni contratto-Chiusura o Disdetta',
    'Metodo di pagamento attivo':
      'Metodo di pagamento-Metodo di pagamento attivo',
    'Attivazione addebito diretto in CC':
      'Metodo di pagamento-Attivazione addebito diretto in CC',
    'Modifica IBAN di addebito in CC':
      'Metodo di pagamento-Modifica IBAN di addebito in CC',
    Rateizzazione: 'Metodo di pagamento-Rateizzazione',
    'Spedizione o ricezione fattura':
      'Informazioni fatture-Spedizione o ricezione fattura',
    'Data emissione fattura': 'Informazioni fatture-Data emissione fattura',
    'Letture contatore': 'Informazioni fatture-Letture contatore',
    'Consumi e importi fatturati':
      'Informazioni fatture-Consumi e importi fatturati',
    Conguaglio: 'Informazioni fatture-Conguaglio',
    'Stato pratiche aperte': 'Supporto clienti-Stato pratiche aperte',
    'Informazioni sulla modulistica':
      'Supporto clienti-Informazioni sulla modulistica',
    'Informazioni su nuove offerte':
      'Supporto clienti-Informazioni su nuove offerte',
    'Problemi e suggerimenti Area Clienti o APP':
      'Supporto clienti-Problemi e suggerimenti Area Clienti o APP'
  },
  mappingArr: [
    'Informazioni contratto-Attivazione',
    'Informazioni contratto-Offerta commerciale sottoscritta',
    'Informazioni contratto-Dati anagrafici',
    'Informazioni contratto-Voltura o Subentro',
    'Informazioni contratto-Chiusura o Disdetta',
    'Metodo di pagamento-Metodo di pagamento attivo',
    'Metodo di pagamento-Attivazione addebito diretto in CC',
    'Metodo di pagamento-Modifica IBAN di addebito in CC',
    'Metodo di pagamento-Rateizzazione',
    'Informazioni fatture-Spedizione o ricezione fattura',
    'Informazioni fatture-Data emissione fattura',
    'Informazioni fatture-Letture contatore',
    'Informazioni fatture-Consumi e importi fatturati',
    'Informazioni fatture-Conguaglio',
    'Supporto clienti-Stato pratiche aperte',
    'Supporto clienti-Informazioni sulla modulistica',
    'Supporto clienti-Informazioni su nuove offerte',
    'Supporto clienti-Problemi e suggerimenti Area Clienti o APP'
  ]
}

export const actions = {
  power: {
    types: ['BT Monofase', 'BT Trifase', 'MT'],
    items: [
      0.5,
      1,
      1.5,
      2,
      2.5,
      3,
      3.5,
      4,
      4.5,
      5,
      5.5,
      6,
      7,
      8,
      9,
      10,
      15,
      20,
      25,
      30,
      'Oltre i 30'
    ]
  },
  counter: {
    shift: ['10 metri', 'Otre 10 metri']
  },
  voltage: {
    types: ['BT Monofase', 'BT Trifase', 'MT']
  }
}

export const paymentTypeLabel = (type) => {
  switch (type) {
    case 'SDD':
      return 'Addebito su conto corrente'
    case 'CCR':
      return 'Carta di credito ricorrente'
    case 'CCOS':
      return 'Carta di credito one shot'
    case 'BLP':
      return 'Bollettino postale'
    case 'BON':
      return 'Bonifico bancario'
    case 'DPAY':
      return 'Digital pay'
    case 'ASS':
      return 'Assegno'
    case 'CAM':
      return 'Cambiale'
    default:
      return ''
  }
}

export const wkProducts1 = [
  'IMPFIX01200520',
  'IMPFIX02200520',
  'IMPFIX+01200520',
  'IMPFIX+02200520',
  'IMPFLEX01200520',
  'IMPFLEX02200520',
  'IMPGASFIX01200520'
]

export const wkProducts2 = [
  'STD_NELFIX+01180717',
  'SLFLE01201016',
  'STD_NELFIX+06180717',
  'SLFIX06201016',
  'SGFIX01201016',
  'SLFIX01201016',
  'CL2AFIX260916',
  'CL3FIX260916',
  'SLFLE+01011017',
  'CL3PUN260916',
  '365GAS150517',
  'CL1AFIX260916',
  'CL2BFIX260916',
  'CL3PUN150115',
  'SLFIX05201016',
  'CL1BFIX260916',
  'CL3PUN191011',
  'SLFIX+01011017',
  'SGFIX0101',
  'SLFLE05201016',
  'SLFLE06201016',
  'SMGAS150914',
  '365GAS150914',
  'SMGAS150517',
  '365GAS260916',
  'CL2APUN201216',
  'PGAS020713',
  'STD_NELFIX+05180717',
  'CL2FIX010212',
  'RDSG01',
  'SLFLE+06011017',
  'CL3PUN140214',
  'SLFLE+05011017',
  'CL2BFIX031013',
  'CL2APUN140214',
  'SLFIX0101',
  'CL1BFIX221014',
  'SLFIX+05011017',
  'SMGAS260916',
  'SLFIX+06011017',
  'CL2AFIX221014',
  'CL2BPUN201216',
  'CL3FIX031013',
  'CL3FIX221014',
  'CL2BFIX221014',
  'CL1AFIX310315',
  'CL2AFIX031013',
  'CL3FIX110113',
  'CL2BFIX110912',
  'GRELEASE_07',
  'CL2BFIX110113',
  'CL1PUNMIN191011',
  'CL2AFIX110113',
  'STD_GRELEASE_07',
  'CL1FIX031013',
  'SLFIX0501',
  'CL2AFIX310315',
  'CL1PUN140214',
  'CL2BFIX310315',
  'cl2Afix110912',
  'ASGAS020713',
  'CL2SMIN110113',
  'RD01',
  'IMFA01',
  'CL3FIX310315',
  '3XTE01C',
  'SLFIX0301',
  'MF03',
  'CL1FIX110113',
  'CL2BPUN140214',
  'CL3FIX110912',
  '3XTE02C',
  'CL2AFIXDT011018',
  'SDS02',
  'RDS01',
  'RDS04',
  'CL1BFIX310315',
  'MF01',
  'RB01',
  'CL1BFIXDT011018',
  'PN02',
  'SPR02',
  'RDS02',
  'SLFIX0601',
  'SLFLE0501',
  'VP02',
  'TND01',
  'VP01',
  'PN01',
  'TND03C',
  'SLFLE0101',
  'CL2BFIXDT011018',
  'TND02C',
  'SDP02',
  'CL1FIX110912',
  'EP01',
  'SPR01',
  'MF02',
  'SLFIX0201',
  'SLFIX0401',
  'SLFLE0201',
  'SLFLE0301',
  'SLFLE0401',
  'SLFLE0601',
  'SDS01',
  'RDS05',
  'RDS03NF',
  'RDS06',
  'SDP01',
  'SCB01NB',
  'CL3FIX010212',
  'CL1AFIX221014',
  'CL1PUN201216',
  'CL3PUN201216',
  'BUS_PSL010117',
  'BUS_PSG010117',
  'CL1AFIXDT011018',
  'CL3FIXDT011018',
  'STD_NELFLEX+01010419',
  'STD_NELFLEX+05010419',
  'OL3FIX011119',
  'OL1AFIX011119',
  'OL2AFIX011119'
]

export const APP_SECTION_MAPPING = {
  loyalty: 'loyaltyPage',
  upsellingLuce: 'eleOffering',
  upsellingGas: 'gasOffering',
  fiberOffering: 'fiberOffering',
  upsellingFibra: 'fiberOffering',
  speedTest: 'speedTest',
  carbonFootprint: 'carbonFootprint',
  welcomekit: 'welcomekit',
  beyondEnergy: 'beyondEnergy',
  loyalty_diary: 'loyaltyPage',
  loyalty_catalog: 'catalogue',
  ownerSupplies: 'ownerSupplies'
}

export const MESSAGE_CATEGORY = {
  utenzaluce: 'service_ele',
  utenzagas: 'service_gas',
  greeners: 'greeners',
  utenzafibra: 'service_fiber',
  vantaggiperte: 'benefits_for_you',
  iniziative: 'initiatives',
  info: 'info'
}

export const STATUS_GREENERS = {
  Nessuno: undefined,
  Pending: 'PENDING',
  'Prospect Puro': 'PURE_PROSPECT',
  Prospect: 'PROSPECT',
  Cliente: 'CUSTOMER'
}

export default {
  BILL_PAYMENT_STATUS: {
    1: 'In scadenza', // this should be 'In scadenza', but requiremnt is to transcode it to 'In pagamento' as for id '2'
    2: 'In pagamento',
    3: 'Scaduta',
    4: 'Pagata',
    5: 'Sospesa per richiesta in corso',
    6: 'In Verifica',
    7: 'Rateizzazione in corso'
  },

  STATUS_PDR: {
    ACTIVE: 'Active',
    PAYED: 'Payed',
    EXPIRED: 'Expired',
    NOT_PAYED: 'Not Payed',
    NONE: 'None',
    CANCELED: 'Canceled',
    CLOSED: 'Closed'
  },

  TRANSLATE_STATUS_PDR: {
    Expired: 'Scaduta',
    Payed: 'Pagata',
    Canceled: 'Annullato',
    Closed: 'Chiuso',
    Active: 'Attiva',
    'Not Payed': 'Da pagare'
  },

  PAYMENT_METHODS: {
    CREDIT_CARD: 'CCR',
    BANK_ACCOUNT: 'SDD',
    POSTALBILL: 'BLP',
    POSTALBILL_EXT: 'Bollettino postale',
    CC_ONE_SHOT: 'CCOS',
    BANK_TRANSFER: 'BON',
    DIGITAL_PAYMENT: 'DPAY'
  },

  CREDIT_CARDS_CIRCUITS: {
    VISA: '01',
    MASTERCARD: '02'
  },

  SUPPLY_STATUS: {
    ACTIVE: 'ACTIVE',
    ACTIVATING: 'ACTIVATING',
    CLOSED: 'STOPPED',
    REJECTED: 'REJECTED'
  },

  SERVICE_TYPE: {
    PHOTOVOLTAIC: 'PHOTOVOLTAIC',
    ELE: 'ELE',
    GAS: 'GAS',
    FIBER: 'FIBER'
  },

  SUPPLY_DETAIL_STATUS: {
    ACTIVE: 'Attivo',
    ACTIVATING: 'In Attivazione',
    CLOSED: 'Cessato',
    REJECTED: 'Rifiutata'
  },

  SUPPLY_TYPES: {
    Fotovoltaico: 'Fotovoltaico',
    ele: 'Luce',
    gas: 'Gas',
    dual: 'Luce - Gas',
    fiber: 'Fiber'
  },

  DEVICES_PHOTOVOLTAIC: {
    BATTERY: 'la batteria',
    PANELS: 'i pannelli',
    INVERTER: "l'inverter"
  },

  CALLCENTER_CHANNELS: {
    LANDLINE: 'LANDLINE',
    MOBILE: 'MOBILE',
    WHATSAPP: 'WHATSAPP',
    EMAIL: 'EMAIL',
    CHAT: 'CHAT',
    CALLBACK: 'CALLBACK'
  },

  CALLCENTER_AREAS: {
    UPSELLING_FIBER: 'UPSELLING_FIBRA',
    UPSELLING_COMMODITY: 'UPSELLING_LUCE_GAS',
    POD_PDR: 'POD_PDR',
    SUPPORT: 'SUPPORTO',
    SUPPORT_FIBER: 'SUPPORTO_FIBRA'
  },

  JOURNEY: {
    TIMER_DOCUMENTI: 'TIMER_DOCUMENTI',
    TIMER_DOCUMENTI_ID: 'TIMER_DOCUMENTI_ID',
    TIMER_BOLLETTA: 'TIMER_BOLLETTA',
    TIMER_PAGAMENTI: 'TIMER_PAGAMENTI',
    TIMER_AUTOLETTURA_LUCE: 'TIMER_AUTOLETTURA_LUCE',
    TIMER_AUTOLETTURA_GAS: 'TIMER_AUTOLETTURA_GAS',
    DOWNLOAD_DOCUMENTI: 'DOWNLOAD_DOCUMENTI_ID',
    DOWNLOAD_BOLLETTA: 'DOWNLOAD_BOLLETTA',
    SEZIONE_ARCHIVIO_BOLLETTE_PDR: 'SEZIONE_ARCHIVIO_BOLLETTE-PDR',
    ERRORE_AUTOLETTURA_ELE: 'ERRORE_AUTOLETTURA_ELE',
    ERRORE_AUTOLETTURA_GAS: 'ERRORE_AUTOLETTURA_GAS'
  },

  MONTHS: {
    '01': 'gennaio',
    '02': 'febbraio',
    '03': 'marzo',
    '04': 'aprile',
    '05': 'maggio',
    '06': 'giugno',
    '07': 'luglio',
    '08': 'agosto',
    '09': 'settembre',
    10: 'ottobre',
    11: 'novembre',
    12: 'dicembre'
  },

  PRODUCTS: {
    IMPFIX01200520: 'Nuovo Business Fix',
    NELFIX24: 'Digital Residenziale 24',
    NEGFIX24: 'Digital Residenziale 24',
    'ST08-PA': 'STD_TOP2008 - ST08-PA',
    STD_CCA_PERC: 'STD_CCA_PERC',
    STD_CL3_PI: 'STD_CL3_PI',
    DUMMY_MIGRAZIONE_ELE: 'DUMMY_MIGRAZIONE_ELE',
    DUMMY_MIGRAZIONE_GAS: 'DUMMY_MIGRAZIONE_GAS',
    EP01: 'Energia Pulita - EP01',
    SDP02: 'Plus - Salvadanaio - SDP02',
    SDS01: 'Sedici - Salvadanaio Special - SDS01',
    SDS02: 'Sedici - Salvadanaio Special - SDS02',
    RDS03NF: 'Sedici - Rid8Special - RDS03NF',
    RISPENERGETICO: 'STD_RISPENERGETICO',
    STD_LARGE07_CL3_CT: 'STD_LARGE07_CL3_CT',
    RDSG01: 'Sedici - Rid8 Special - RDSG01',
    SPR02: 'Record Sprint - SPR02',
    SCB01NB: 'Sconto Business - SCB01NB',
    PN01: 'La Prova del 9 - PN01',
    PN02: 'La Prova del 9 - PN02',
    STD_PC2005: 'STD_PC2005',
    RDS06: 'Sedici - Rid8Special - RDS06',
    '3XTE01C': '3 Per Te - 3XTE01C',
    TND01: 'Tandem - TND01',
    CL3PUN191011: 'Borsa Più - CL3PUN191011',
    CL1PUNMIN191011: 'Punta Al Minimo - CL1PUNMIN191011',
    GDS1: 'STD_GDS1',
    CL1FIX110113: 'Scegli il Fisso TOP - CL1FIX110113',
    CL2BFIX110113: 'Scegli il Fisso TOP - CL2BFIX110113',
    CL2AFIX110113: 'Scegli il Fisso TOP - CL2AFIX110113',
    CL3FIX110113: 'Scegli il Fisso TOP - CL3FIX110113',
    CL2SMIN110113: 'Scegli il Minimo - CL2SMIN110113',
    CL2BFIX110912: 'Scegli il Fisso - CL2BFIX110912',
    cl2Afix110912: 'Scegli il Fisso - cl2Afix110912',
    CL3FIX110912: 'Scegli il Fisso - CL3FIX110912',
    CL1FIX110912: 'Scegli il Fisso - CL1FIX110912',
    '3XTE02C': '3 Per Te - 3XTE02C',
    CL2AFIX031013: 'Fisso su Misura - CL2AFIX031013',
    CL2BFIX031013: 'Fisso su Misura - CL2BFIX031013',
    CL3FIX031013: 'Fisso su Misura - CL3FIX031013',
    CL1FIX031013: 'Fisso su Misura - CL1FIX031013',
    IMFA01: 'InPresa Attiva - IMFA01',
    SGFIX0101: 'Soluzione Gas Fix Monovolume - SGFIX0101',
    CL2APUN140214: 'BORSA su MISURA - CL2APUN140214',
    CL3PUN140214: 'Borsa Su Misura - CL3PUN140214',
    CL1PUN140214: 'BORSA su MISURA - CL1PUN140214',
    CL2BPUN140214: 'BORSA su MISURA - CL2BPUN140214',
    SLFIX0101: 'Soluzione Luce Fix - SLFIX0101',
    SLFIX0201: 'Soluzione Luce Fix - SLFIX0201',
    SLFIX0301: 'Soluzione Luce Fix - SLFIX0301',
    SLFIX0401: 'Soluzione Luce Fix - SLFIX0401',
    SLFIX0501: 'Soluzione Luce Fix - SLFIX0501',
    SLFIX0601: 'Soluzione Luce Fix - SLFIX0601',
    '365GAS150914': 'Gas TreSeiCinque - 365GAS150914',
    SMGAS150914: 'Smart GAS - SMGAS150914',
    SLFLE0301: 'Soluzione Luce Flexi - SLFLE0301',
    SLFLE0401: 'Soluzione Luce Flexi - SLFLE0401',
    SLFLE0501: 'Soluzione Luce Flexi - SLFLE0501',
    SLFLE0601: 'Soluzione Luce Flexi - SLFLE0601',
    CL2AFIX221014: 'Fisso 365 - CL2AFIX221014',
    CL3FIX221014: 'Fisso 365 - CL3FIX221014',
    CL1AFIX221014: 'Fisso 365 - CL1AFIX221014',
    CL2BFIX221014: 'Fisso 365 - CL2BFIX221014',
    CL1BFIX221014: 'Fisso 365 - CL1BFIX221014',
    TND02C: 'Tandem - TND02C',
    TND03C: 'Tandem - TND03C',
    SPR01: 'Record Sprint - SPR01',
    VP01: '20 in Poppa - VP01',
    VP02: '20 in Poppa - VP02',
    MF01: 'Meglio del Fisso - MF01',
    MF02: 'Meglio del Fisso - MF02',
    MF03: 'Meglio del Fisso - MF03',
    SLFLE0101: 'Soluzione Luce Flexi - SLFLE0101',
    SLFLE0201: 'Soluzione Luce Flexi - SLFLE0201',
    RDS01: 'Sedici - Rid8Special - RDS01',
    RDS02: 'Sedici - Rid8Special - RDS02',
    RDS04: 'Sedici - Rid8Special - RDS04',
    RDS05: 'Sedici - Rid8Special - RDS05',
    SDP01: 'Plus - Salvadanaio - SDP01',
    CL3FIX010212: 'Fissa Al Minimo - CL3FIX010212',
    CL2FIX010212: 'Fissa Al Minimo TOP - CL2FIX010212',
    RB01: 'Ripristino Business EE - RB01',
    SDPC01: 'Plus Salvadanaio - SDPC01',
    RD01: 'Strike Rid8 - RD01',
    STD_ENEL_QTA: 'STD_ENEL_QTA',
    'ST08-IA': 'STD_TOP2008 - ST08-IA',
    STD_PC2005NEW: 'STD_PC2005NEW',
    CL3PUN150115: 'Borsa Più - CL3PUN150115',
    PGAS020713: 'Gas Più - PGAS020713',
    ASGAS020713: 'Anni Sicuri - ASGAS020713',
    STD_G_GRELEASE_07: 'Gas Release - STD_G_GRELEASE_07',
    GRELEASE_07: 'Scegli il Fisso - STD_G_GRELEASE_07',
    STD_GRELEASE_07: 'Scegli il Dinamico - STD_G_GRELEASE_07',
    'STD_G_KB-G_KM-0708': 'STD_G_KB-G_KM-0708',
    PRZFISSOM: 'PRZFISSOM',
    'PRZFISSOM-PFOR': 'PRZFISSOM-PFOR',
    'ST08-CT': 'STD_TOP2008 - ST08-CT',
    ST08: 'STD_TOP2008 - ST08',
    STD_LARGE08_CL1_IA: 'STD_LARGE08_CL1_IA',
    STD_LARGE08_CL3_IA: 'STD_LARGE08_CL3_IA',
    STD_LARGE07_CL1_PA: 'STD_LARGE07_CL1_PA',
    'TRE - STD_CL3PA_170910': 'TRE - STD_CL3PA_170910',
    'DUMMY ELE': 'DUMMY ELE',
    'DUMMY GAS': 'DUMMY GAS',
    CM01: 'Casa Mia - CM01',
    L24C02: 'Libero 24 - L24C02',
    L24C01: 'Libero 24 - L24C01',
    L24T01: 'Libero 24 - L24T01',
    AL01: 'Aladino - AL01',
    SDC01: 'Salvadanaio - SDC01',
    LCA01: 'Libero Casa - LCA01',
    LCA02C: 'Libero Casa - LCA02C',
    '10L01': 'Casa 10 e Lode - 10L01',
    TVWEB1Q13: 'TiVoglioWeb - TVWEB1Q13',
    TVWEB3Q11: 'TiVoglioWeb - TVWEB3Q11',
    SCC01NB: 'Sconto Casa - SCC01NB',
    RC01EE: 'Ripristino Casa EE - RC01EE',
    ATG01: 'A Tutto Gas - ATG01',
    ATG02: 'A Tutto Gas - ATG02',
    ATG03: 'A Tutto Gas - ATG03',
    ATG04: 'A Tutto Gas - ATG04',
    SDG01: 'Salvadanaio Gas - SDG01',
    ZP01: 'Zero Pensieri - ZP01',
    ZP02: 'Zero Pensieri - ZP02',
    SLCFIX0101: 'Soluzione Luce Casa MONO - SLCFIX0101 ',
    'SLCFIX0201 ': 'Soluzione Luce Casa BIORARIA - SLCFIX0201 ',
    RCGAS01: 'Ripristino Casa GAS - RCGAS01',
    SGCFIX0101: 'Soluzione Gas Casa - SGCFIX0101',
    ESMA01: 'ESMA01 - EcoDomestico',
    CL1BFIX310315: 'Fisso VentiQuattro - CL1BFIX310315',
    CL1AFIX310315: 'Fisso VentiQuattro - CL1AFIX310315',
    CL2BFIX310315: 'Fisso VentiQuattro - CL2BFIX310315',
    CL2AFIX310315: 'Fisso VentiQuattro - CL2AFIX310315',
    CL3FIX310315: 'Fisso VentiQuattro - CL3FIX310315',
    RES_NELFIX0101: 'Next Energy – Luce',
    RES_NEGFIX0101: 'Next Energy – Gas',
    CL1AFIX260916: 'Fisso 365 - CL1AFIX260916',
    CL1BFIX260916: 'Fisso 365 - CL1BFIX260916',
    CL2AFIX260916: 'Fisso 365 - CL2AFIX260916',
    CL2BFIX260916: 'Fisso 365 - CL2BFIX260916',
    CL3FIX260916: 'Fisso 365 - CL3FIX260916',
    CL3PUN260916: 'Borsa Più - CL3PUN260916',
    '365GAS260916': 'Gas TreSeiCinque - 365GAS260916',
    SMGAS260916: 'Smart GAS - SMGAS260916',
    SLFIX01201016: 'Soluzione Luce Fix - SLFIX01201016',
    SLFIX05201016: 'Soluzione Luce Fix - SLFIX05201016',
    SLFIX06201016: 'Soluzione Luce Fix - SLFIX06201016',
    SLFLE01201016: 'Soluzione Luce Flexi - SLFLE01201016',
    SLFLE05201016: 'Soluzione Luce Flexi - SLFLE05201016',
    SLFLE06201016: 'Soluzione Luce Flexi - SLFLE06201016',
    SGFIX01201016: 'SGFIX01201016 - Soluzione Gas Fix',
    CL1PUN201216: 'Borsa su Misura - Misura TRE',
    CL2APUN201216: 'Borsa su Misura - Misura Due Special',
    CL2BPUN201216: 'Borsa su Misura - Misura Due Standard',
    CL3PUN201216: 'Borsa su Misura - Misura MONO',
    RES_TS010117: 'Tutela Simile - TS_RES01012017',
    BUS_TS010117: 'Tutela Simile - TS_BUS01012017',
    RES_PSL010117: 'Prodotto Standard Luce - PSL_RES01012017',
    BUS_PSL010117: 'Prodotto Standard Luce - PSL_BUS01012017',
    RES_PSG010117: 'Prodotto Standard Gas - PSG_RES01012017',
    BUS_PSG010117: 'Prodotto Standard Gas - PSG_BUS01012017',
    '365GAS150517': 'Prodotto Standard Gas - 365GAS150517',
    SMGAS150517: 'Prodotto Standard Gas – SMGAS150517',
    'STD_NELFIX+01180717': 'Next Energy Business Luce + 01180717',
    'STD_NELFIX+05180717': 'Next Energy Business Luce + 05180717',
    'STD_NELFIX+06180717': 'Next Energy Business Luce + 06180717',
    RES_NELFIX4Y0101: 'Next Energy 4 you – Luce',
    RES_NEGFIX4Y0101: 'Next Energy 4 you – Gas',
    'SLFIX+01011017': 'Soluzione Luce Fix + - SLFIX + 01011017',
    'SLFIX+05011017': 'Soluzione Luce Fix + - SLFIX + 05011017',
    'SLFIX+06011017': 'Soluzione Luce Fix + - SLFIX + 06011017',
    'SLFLE+01011017': 'Soluzione Luce Flexi + - SLFLE + 01011017',
    'SLFLE+05011017': 'Soluzione Luce Flexi + - SLFLE + 05011017',
    'SLFLE+06011017': 'Soluzione Luce Flexi + - SLFLE + 06011017',
    STD_EL_PCT01010118_F: 'Sorgenia offerta Placet Luce Monoraria – STD FIX',
    RES_GA_PCT01010118_F: 'Sorgenia offerta Placet Gas – RES FIX',
    RES_EL_PCT01010118_F: 'Sorgenia offerta Placet Luce Monoraria – RES FIX',
    RES_EL_PCT01010118_V: 'Sorgenia offerta Placet Luce Monoraria – RES VAR',
    STD_EL_PCT01010118_V: 'Sorgenia offerta Placet Luce Monoraria– STD VAR',
    RES_GA_PCT01010118_I: 'Sorgenia offerta Placet Gas – RES VAR',
    STD_GA_PCT01010118_F: 'Sorgenia offerta Placet Gas – STD FIX',
    STD_GA_PCT01010118_I: 'Sorgenia offerta Placet Gas – STD VAR',
    STD_NELFIXEBUS010318: 'Next Energy e-Business Luce 01010318',
    STD_NEGFIXEBUS010318: 'Next Energy e-Business Gas 01010318',
    CL3PUN010318: 'CL3PUN010318 - PUN per fasce',
    STD_NELFIXEBUS010618: 'Next e-Business Luce 01010618',
    STD_NEGFIXEBUS010618: 'Next e-Business Gas 01010618',
    SGCFIX0102: 'SGCFIX0102 - Soluzione Gas Casa',
    RES_NELFIX0102: 'Next Energy - Luce',
    RES_NEGFIX0102: 'Next Energy - Gas',
    CL1BFIXDT011018: 'Fisso Ora - CL1BFIXDT011018',
    CL1AFIXDT011018: 'Fisso Ora - CL1AFIXDT011018',
    CL2BFIXDT011018: 'Fisso Ora - CL2BFIXDT011018',
    CL2AFIXDT011018: 'Fisso Ora - CL2AFIXDT011018',
    CL3FIXDT011018: 'Fisso Ora - CL3FIXDT011018',
    'STD_NELFLEX+01010419': 'Next Energy Business Luce Flexi + Monorario',
    'STD_NELFLEX+05010419': 'Next Energy Business Luce Flexi + Biorario',
    OL3FIX011119: 'Oltre il Fisso MONORARIO - OL3FIX011119',
    OL1AFIX011119: 'Oltre il Fisso FASCE - OL1AFIX011119',
    OL2AFIX011119: 'Oltre il Fisso P/OP - OL2AFIX011119',
    RES_NEFBR: 'Next Energy - Fibra',
    RES_NELFIX0103: 'Next Energy - Luce 3',
    RES_NEGFIX0103: 'Next Energy - Gas 3',
    RES_NEFIBR0101: 'Next Energy - Fibra'
  },

  OFFERS: {
    RES_NFX0101: 'Next Fibra',
    NEGFIXEBUS010618: 'Next e-Business Gas 01010618',
    NELFIXEBUS010618: 'Next e-Business Luce 01010618',
    STD_GAS_PLACET_FIX: 'Placet - Gas',
    RES_GAS_PLACET_IND: 'Placet - Gas',
    STD_GAS_PLACET_IND: 'Placet - Gas',
    RES_GAS_PLACET_FIX: 'Placet - Gas',
    STD_ELE_PLACET_FIX: 'Placet - Luce',
    RES_ELE_PLACET_VAR: 'Placet - Luce',
    STD_ELE_PLACET_VAR: 'Placet - Luce',
    RES_ELE_PLACET_FIX: 'Placet - Luce',
    NEGFIX0103: 'Next Energy - Gas V2',
    NELFIX0103: 'Next Energy - Luce V2',
    NEGFIX0102: 'Next Energy - Gas V2',
    NELFIX0102: 'Next Energy - Luce V2',
    NEGFIX4Y0101: 'Next Energy 4 you - Gas V1',
    NELFIX4Y0101: 'Next Energy 4 you - Luce V1',
    NEGFIXEBUS0101: 'Next Energy e-Business - Gas V1',
    NELFIXEBUS0101: 'Next Energy e-Business - Luce V1',
    NEGFIX0101: 'Next Energy - Gas V1',
    NELFIX0101: 'Next Energy - Luce V1',
    // 'NELFIX4Y0101': 'Next Energy 4 you - Gas V1',
    NEPIVAL0101: 'Next Energy Business - Luce',
    NEPIVAG0101: 'Next Energy Business - Gas',
    BUS_TS010117: 'Tutela Simile Business V1',
    RES_TS010117: 'Tutela Simile Residenziale V1',
    // 'NEGFIX0101': 'Next Energy - Dual V1',
    // 'NELFIX0101': 'Next Energy - Dual V1',
    'SLCFIX0201+SGCFIX0101': 'Soluzione Luce Casa V2&Soluzione Gas Casa',
    'SLCFIX0101+SGCFIX0101': 'Soluzione Luce Casa V1&Soluzione Gas Casa',
    SGCFIX0101: 'Soluzione Gas Casa',
    SLCFIX0201: 'Soluzione Luce Casa V2',
    SLCFIX0101: 'Soluzione Luce Casa V1',
    SLFLE0601: 'Luce flexi V6',
    SLFLE0501: 'Luce flexi V5',
    SLFLE0401: 'Luce flexi V4',
    SLFLE0301: 'Luce flexi V3',
    SLFLE0201: 'Luce flexi V2',
    SLFLE0101: 'Luce flexi V1',
    SGFIX0101: 'Gas fix V1',
    SLFIX0601: 'Luce fix V6',
    SLFIX0501: 'Luce fix V5',
    SLFIX0401: 'Luce fix V4',
    SLFIX0301: 'Luce fix V3',
    SLFIX0201: 'Luce fix V2',
    SLFIX0101: 'Luce fix V1',
    IMFA01: 'InPresa Attiva',
    ZP02SU: 'ZERO PENSIERI V4',
    ZP02BI: 'ZERO PENSIERI V4',
    ZP02FA: 'ZERO PENSIERI V4',
    ZP02SM: 'ZERO PENSIERI V4',
    ZP02SI: 'ZERO PENSIERI V4',
    ATG04P7: 'A TUTTO GAS V2',
    ATG04P6: 'A TUTTO GAS V2',
    ATG04P5: 'A TUTTO GAS V2',
    ATG04P4: 'A TUTTO GAS V2',
    ATG04P3: 'A TUTTO GAS V2',
    ATG04P2: 'A TUTTO GAS V2',
    ATG04P1: 'A TUTTO GAS V2',
    SCC01NB: 'Sconto Casa',
    XXXX: 'Azienda',
    ESIN01: 'Ecodomestico',
    ESMA01: 'Ecodomestico',
    ESUP01: 'Ecodomestico',
    EBIG01: 'Ecodomestico',
    EFAM01: 'Ecodomestico',
    TVWEB1Q13: 'Ti voglio web',
    '3XTE02C': ' Tre per Te',
    'TVWEB1Q13+ATG03*': 'Ti voglio web+A tutto Gas',
    GSM02: 'GAS SU MISURA',
    '3XTE01C': ' Tre per Te',
    'TVWEB3Q11+ATG03*': 'Ti voglio web+A tutto Gas',
    ATG03S: 'A TUTTO GAS',
    'ATG03N ': 'A TUTTO GAS',
    ATG03A: 'A TUTTO GAS',
    ATG03C: 'A TUTTO GAS',
    L24C02: 'Libero 24',
    ZP01SF: 'ZERO PENSIERI V3',
    ZP01BF: 'ZERO PENSIERI V3',
    ZP01F: 'ZERO PENSIERI V3',
    ZP01S: 'ZERO PENSIERI V3'
  }
}
export { CITIES }

import lista_fornitori from './lista_fornitori.json'
import lista_fornitori_pre from './lista_fornitori_pre.json'
export const PROVIDERS =
  ENV_CONFIGS.ENV === 'pro' ? lista_fornitori : lista_fornitori_pre

export const KGCO2_RATIO = 0.45857

export const RADIO = 'RADIO'
export const CHECKBOX = 'CHECKBOX'
export const INTERVAL = 'INTERVAL'
export const TEXT = 'TEXT'
export const DROPDOWN = 'DROPDOWN'
export const RATING = 'RATING'
export const RANKING = 'RANKING'

export const PAGINATION_GREENERS_COINS = 7

export const CDN_URL = 'https://media-platform.doxee.com/da-purl/document'

export const OVERALL_STATUS = {
  NO_ANOMALY: 'NO_ANOMALY',
  ALARM: 'ALARM',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  NOT_COMMUNICATING: 'NOT_COMMUNICATING',
  UNKNOWN: 'UNKNOWN'
}

export const ENERGY_DIRECTION = {
  IN: 'IN',
  OUT: 'OUT',
  NONE: 'NONE'
}
